import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const Us = () => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: 5,
      width: "100%",
    }}
  >
    <StaticImage
      src="../../../../images/flavio-and-max.jpg"
      alt="Flavio and Massimo"
      style={{
        gridColumn: "1 / 3",
        gridRow: 1,
        width: "100%",
        mx: "auto",
      }}
    />
    <Box
      sx={{
        p: 5,
        py: [0, 0, 5],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gridRow: [3, 3, 2],
        gridColumn: ["1/3", "1/3", "1"],
      }}
    >
      <Typography variant="large" sx={{ fontWeight: 500 }}>
        Massimo,
        <Box as="span" sx={{ fontWeight: 300, fontStyle: "italic", ml: 3 }}>
          the design guy
        </Box>
      </Typography>
      <Typography
        variant="normal"
        sx={{ textAlign: "center", maxWidth: 600, p: 5 }}
      >
        I draw, like pencils.
      </Typography>
    </Box>
    <Box
      sx={{
        p: 5,
        py: [0, 0, 5],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gridRow: 2,
        gridColumn: ["1/3", "1/3", "2"],
      }}
    >
      <Typography variant="large" sx={{ fontWeight: 500 }}>
        Flavio,
        <Box as="span" sx={{ fontWeight: 300, fontStyle: "italic", ml: 3 }}>
          the physics guy
        </Box>
      </Typography>
      <Typography
        variant="normal"
        sx={{ textAlign: "center", maxWidth: 600, p: [4, 5] }}
      >
        One day, in the XXI century, I started teaching physics in high school.
        I'm still there.
      </Typography>
    </Box>
  </Box>
)

export default Us
