import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Page from "~common/components/Page";
import OurStory from "~sections/OurStory";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Page seo={{
  title: "About Us",
  description: "Who is behind Almagesto project"
}}>
    <Page.Header downArrows>
      <Page.Header.Title>About Us</Page.Header.Title>
    </Page.Header>
    <Page.Body>{children}</Page.Body>
  </Page>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <OurStory mdxType="OurStory" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      