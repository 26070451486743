import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section from "~common/components/Section";
import Us from "./components/Us";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Section bg="primary" color="secondary">
    <Section.Header>Our Story</Section.Header>
    <Section.Body>{children}</Section.Body>
    <Section.Footer logo />
  </Section>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Ciao! We are a designer and a physics teacher from Biella, Italy.`}</p>
    <p>{`Some time ago we asked ourselves `}<strong parentName="p">{`how the Solar System would look like in a frame of reference other than the Heliocentric one`}</strong>{`.`}</p>
    <p>{`To find an answer, we started developing a `}<strong parentName="p">{`software able to trace out the paths of the planets of the Solar System`}</strong>{` as they would appear to an observer sitting on one of them.`}</p>
    <p>{`The results were so graphically appealing and conceptually intriguing that we thought it would be great to `}<strong parentName="p">{`print them on high quality posters`}</strong>{`.`}</p>
    <p>{`We have then decided to create an `}<strong parentName="p">{`eye-catching design item`}</strong>{` capable of communicating something interesting and very unique about our local universe.`}</p>
    <p>{`Given that our main goal was to create a design product `}<strong parentName="p">{`without sacrificing scientific accuracy`}</strong>{`, we opted to base our software on `}<strong parentName="p">{`NASA ephemerides`}</strong>{` to ensure the `}<strong parentName="p">{`highest precision with reference to the position of the planets`}</strong>{`.`}</p>
    <p>{`Even with the best sources for astronomical data, realizing a readable, communicative and beautiful print was a difficult task.`}</p>
    <p>{`To overcome all the challenges, like the coexistence of very different scales or the intricacy of the trajectories, we have developed `}<strong parentName="p">{`algorithms and graphical themes`}</strong>{` which ensure an `}<strong parentName="p">{`optimal and beautiful product for the full spectrum of cases`}</strong>{`, `}<strong parentName="p">{`even in printing process`}</strong>{`.`}</p>
    <p>{`After numerous prototypes and iterations, `}<strong parentName="p">{`we are finally ready to share our prints with the entire world`}</strong>{`.`}</p>
    <Us mdxType="Us" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      